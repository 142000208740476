import styled from 'styled-components';

const Wrapper = styled.div`
  //margin: 5%;
  /* padding: 5% 10%; */
  background: #f1f1f1;
  justify-content: center;
  display: flex;
  width: 100%;

  .container {
    /* margin-top: 10px; */
    width: 100%;
    padding: 20px 3%;
    background: #ffffff !important;
    border: 1px solid #cecece;
    border-radius: 10px;
  }

  .title {
    font-size: 1.2rem;
    color: #008442 !important;
  }

  p {
    color: #5a5a5a;
    font-size: 0.9rem;
  }

  .containerImg img {
    height: 100px;
    margin: 20px 0px;
  }

  .ant-collapse-header {
    font-size: 14px;
    font-weight: 600;
  }

  .ant-collapse-header:hover {
    background: #008442;
    color: #fff;
  }

  #backButton {
  background-color: transparent;
  color: #00853b;
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

#backButton:hover {
  color: #00853b;
  transform: scale(1.2);
  transition: all 0.4s ease-out;
  box-shadow: none !important;
}

  @media (max-width: 600px) {
    /* .container {
      width: 90%;
      margin: 10% 0px;
    } */
    .containerImg img {
      height: 60px;
      margin: 20px 0px;
    }

    .ant-collapse-header {
      font-size: 14px;
      font-weight: 600;
    }
    .ant-collapse-header:hover {
      background: #008442;
      color: #fff;
    }
  }

  @media (max-width: 800px) {
    .card-01 {
      flex: 100%;
      max-width: 600px;
    }
  }

  @media (max-width: 800px) {
    .subtitle-card {
      flex: 100%;
      max-width: 600px;
    }
  }

  @media screen and (min-width: 500px) {
    .title-card {
      font-size: calc(16px + (8 / 1200) * 100 * 1vw);
    }
  }
`;

export default Wrapper;
