import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Form, Button, Space, Select, Upload, message } from 'antd';
import { PlusOutlined, InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/auth';

import devPrint from '../../utils/devPrint';
import InputImei from '../../components/inputImei';
import isValidIMEI from '../../utils/imeiCheck';

import styles from './styles.module.css';
import { useGlobalData } from '../../hooks/globalData';
import cellphoneService from '../../services/cellphone';
import { CellphoneBody, CellphoneForm } from 'interfaces/cellphone';

interface ImeiProps {
  key: number;
  value: string;
}

export default function RegisterPhones() {
  const { signOut } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loadingManufacturers, setLoadingManufacturers] = useState(false);

  const {
    // eslint-disable-next-line no-unused-vars
    fabricantes,
    setFabricantes,
    modelos,
    setModelos,
    setAparelhos,
  } = useGlobalData();
  const {
    getAllManufacturers,
    getModelFromManufacturers,
    getCellhoneData,
    saveCellphone,
    updateCellphone,
    getDocumentFromId,
  } = cellphoneService();
  const [loading, setloading] = useState(false);
  const [file, setFile] = useState();
  const [src, setSrc] = useState<string>();

  const [imeiList, setImeiList] = useState<ImeiProps[]>([
    { key: 1, value: '' },
  ]);

  const imeiCount = useRef(50);

  // eslint-disable-next-line no-unused-vars
  const [props, setProps] = useState({});
  // const [isVerify, setVerify] = useState(false);

  const isEditing = !!id;

  function errorHandler(err: any) {
    // devPrint(err);
    if (err.message === '401') {
      signOut();
    }
    if (err.message === '404') {
      devPrint('Temos um erro 404');
    }
  }

  const loadProps = (srcAux: string) => ({
    defaultFileList: srcAux
      ? [
          {
            uid: '2',
            name: 'anexo',
            status: 'done',
            url: srcAux,
          },
        ]
      : null,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        devPrint(info.file, info.fileList);
      }
      if (status === 'done') {
        // message.success(`${info.file.name} file upload sucessfully`);
      } else if (status === 'erro') {
        message.error(`Falha no upload do arquivo ${info.file.name}`);
      }
    },
    onDrop(e: any) {
      devPrint('Dropped files', e.dataTransfer.files);
    },
  });

  const notaFiscal = useCallback(async () => {
    if (id) {
      try {
        const res = await getDocumentFromId(id);
        const { data, headers } = res || {
          data: null,
          headers: null,
        };
        if (data?.byteLength > 0) {
          const type = headers['content-type'];
          const binaryData = [];
          binaryData.push(data);

          const srcNew = URL.createObjectURL(new Blob(binaryData, { type }));

          setProps(loadProps(srcNew));
          setSrc(srcNew);
        } else {
          setSrc('');
        }
      } catch (err) {
        console.error(err);
        // errorHandler(err);
      }
    }
  }, [id]);

  async function alteraAparelho(body: CellphoneBody) {
    try {
      const res: any = await updateCellphone(id, body);
      if (res.data.key && res.data.key === 'database.celular.salvo.conflito') {
        return toast.error(res.data?.message);
      }

      toast.success(res.data.message);
      setAparelhos([]);
      navigate('/home');
    } catch (err) {
      errorHandler(err);
      toast.warning('Erro ao alterar dados!');
      setloading(false);
    }
  }

  const onFinish = useCallback(
    (values: CellphoneForm & { imeis: string[] }) => {
      try {
        let finalImeis = imeiList.map(imei => imei.value);

        if (!finalImeis.length) {
          toast.error('Você deve adicionar pelo menos 1 IMEI');
          return;
        }

        finalImeis = finalImeis.filter(imei => imei);

        setloading(true);
        const body: CellphoneBody = {
          fabricante: values.fabricante,
          modelo: values.modelo,
          numeroDeSerie: values.numeroDeSerie,
          imeis: finalImeis.map(imei => ({ numeroImei: imei })),
          statusAlerta: 'SEM_ALERTA',
          file,
        };
        if (id != null) {
          alteraAparelho(body);
          // eslint-disable-next-line no-use-before-define
        } else adicionaAparelho(body);
      } catch (err) {
        //
      } finally {
        setloading(false);
      }
    },
    [imeiList, file]
  );

  const { Dragger } = Upload;

  function getBase64(img: any, callback: (value: any) => void) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUpload = useCallback(
    (fileUpload: any) => {
      getBase64(fileUpload, imageUrl => setFile(imageUrl));
      return false;
    },
    [setFile]
  );

  const atualizaListaFabricantes = useCallback(async () => {
    try {
      setLoadingManufacturers(true);
      const manufacturers = await getAllManufacturers();
      setFabricantes(
        manufacturers?.sort((a: any, b: any) => {
          const nomeA = a.nome.toUpperCase();
          const nomeB = b.nome.toUpperCase();

          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }
          return 0;
        }) || []
      );
      // setModelos([]);
    } catch (err) {
      console.error(err);
      // errorHandler(err);
    } finally {
      setLoadingManufacturers(false);
    }
  }, []);

  // async function atualizaModelos(fabricanteName: string) {
  //   try {
  //     const models = await getModelFromManufacturers(fabricanteName);
  //     setModelos(models);
  //   } catch (err) {
  //     errorHandler(err);
  //   }
  // }

  const preencheEdicao = useCallback(async () => {
    try {
      const data = await getCellhoneData(id);
      const { fabricante, modelo, numeroDeSerie, imeis }: CellphoneBody = data;

      setImeiList(
        imeis?.map((imei, i) => ({ key: i, value: imei.numeroImei })) || []
      );

      form.setFieldsValue({
        fabricante,
        modelo,
        numeroDeSerie,
        imeis: imeis?.map(imei => imei.numeroImei),
      });
    } catch (err) {
      console.error(err);
      // errorHandler(err);
    }
  }, [form, id]);

  useEffect(() => {
    atualizaListaFabricantes();

    if (id != null) {
      // setIsEditing(true);
      preencheEdicao();
      notaFiscal();
    } else {
      setSrc('');
    }
  }, [atualizaListaFabricantes, id, notaFiscal]);

  async function adicionaAparelho(body: CellphoneBody) {
    try {
      const addCellphoneResponse: any = await saveCellphone(body);
      if (
        addCellphoneResponse.data.key &&
        addCellphoneResponse.data.key === 'database.celular.salvo.conflito'
      ) {
        return toast.error(addCellphoneResponse.data?.message);
      }

      if (Object.keys(addCellphoneResponse).length) {
        toast.success('Dispositivo cadastrado');
        setAparelhos([]);
        // setloading(false);
        navigate('/home');
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.warning('Erro ao adicionar dispositivo');
      setloading(false);
    }
  }

  const addImei = () => {
    imeiCount.current++;
    setImeiList(prev => [...prev, { key: imeiCount.current + 1, value: '' }]);
  };

  const handleChangeImei = (key: number, value: string) => {
    setImeiList(prev =>
      prev.map(imei => {
        if (imei.key === key) {
          imei.value = value;
        }
        return imei;
      })
    );
  };

  const removeImei = (imei: ImeiProps) => {
    setImeiList(prev => prev.filter(i => i.key !== imei.key));
  };

  return (
    <div className={styles.formulario}>
      <div className={styles.camposFormulario}>
        {loading ? (
          <svg className="spinner" width="16" />
        ) : isEditing ? (
          <h3>Atualização de dispositivo</h3>
        ) : (
          <h3>Cadastro de dispositivo</h3>
        )}
        <br />
        <div className={styles.alertGreyMessage}>
          <p className={styles.infoText}>
            Para localizar o número de série e o(s) IMEI(s) do seu dispositivo,
            digite *#06# no seu smartphone.
          </p>
          <p className={styles.infoText}>
            Se o seu dispositivo possuir mais de um IMEI, é essencial incluir
            todos no cadastro, facilitando assim sua identificação.
          </p>
        </div>

        <br />

        <Form
          form={form}
          layout="vertical"
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          initialValues={{ imeis: [''] }}
        >
          <Form.Item
            name="fabricante"
            label="Fabricante"
            rules={[{ required: true, message: 'Campo Obrigatório' }]}
          >
            <Select
              loading={loadingManufacturers}
              showSearch
              className="container-dropdown"
              // className={styles.linhaMaiorEsquerda}
              placeholder="Fabricante"
              // onChange={value => atualizaModelos(value)}
            >
              {fabricantes?.map((fab, index) => {
                return (
                  <Select.Option
                    // onClick={devPrint("Teste")
                    key={index}
                    value={fab.nome}
                  >
                    {fab.nome}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className={styles.linhaMaiorEsquerda} />
          <div className={styles.linhaMaiorEsquerda}>
            <Form.Item
              label="Modelo"
              name="modelo"
              // hidden="true"
              rules={[{ required: true, message: 'Campo Obrigatório' }]}
            >
              <Input />
              {/* <Select
                showSearch
                // className="container-dropdown"
                className={styles.linhaMaiorEsquerda}
                placeholder="Modelo"
              >
                {(modelos || []).map((modelo, index) => (
                  <Select.Option key={index} value={modelo.nome}>
                    {modelo.nome}
                  </Select.Option>
                ))}
              </Select> */}
            </Form.Item>
          </div>
          <div className={styles.linhaMaiorEsquerda}>
            <Form.Item label="Número de série" name="numeroDeSerie">
              <Input
                className={styles.inputStyle}
                placeholder="Número de série"
              />
            </Form.Item>
          </div>

          <Form.List name="imeis">
            {fields => (
              <>
                {imeiList.map(imei => (
                  <Space
                    key={imei.key}
                    align="baseline"
                    className={styles.space}
                  >
                    <div className={styles.contentImei}>
                      <Form.Item
                        key={imei.key}
                        name={imei.key}
                        label="IMEI"
                        rules={[
                          {
                            required: true,
                            // eslint-disable-next-line prefer-promise-reject-errors
                            validator: (_, value) =>
                              isValidIMEI(value || '')
                                ? Promise.resolve()
                                : // eslint-disable-next-line prefer-promise-reject-errors
                                  Promise.reject(
                                    value ? 'IMEI inválido' : 'Obrigatório'
                                  ),
                          },
                        ]}
                      >
                        <InputImei
                          key={imei.key}
                          type="number"
                          placeholder="IMEI"
                          className={styles.inputStyleImei}
                          onChange={e => handleChangeImei(imei.key, e)}
                        />
                      </Form.Item>
                      <h2 style={{ marginTop: '20px' }}>/</h2>
                      <Form.Item
                        // style={{ width: '20px' }}
                        key={imei.key + 400}
                        label=""
                      >
                        <Input
                          key={imei.key}
                          type="number"
                          placeholder="00"
                          className={styles.inputStyleImeiDumb}
                        />
                      </Form.Item>
                      <DeleteOutlined
                        className={styles.buttonDelete}
                        onClick={() => removeImei(imei)}
                      />
                    </div>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    className={styles.buttonAdd}
                    type="dashed"
                    onClick={
                      () => addImei()
                      // { name: '', key: fields.length + 100 },
                      // fields.length + 100
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    IMEI
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          {!src && (
            <Form.Item label="Anexar nota fiscal" name="file">
              <Dragger
                {...props}
                beforeUpload={info => beforeUpload(info)}
                onRemove={() => devPrint({ file: null })}
                maxCount={1}
                accept={'image/png, image/jpeg, application/pdf'}
                name={'file'}
              >
                <div className={styles.uploadIcon}>
                  <InboxOutlined />
                </div>
                <div className={styles.upLoadFile}>
                  Clique ou arraste o png, jpg ou pdf da nota fiscal para esta
                  área de upload
                </div>
                <div className={styles.uploadHint}>
                  Suporte para upload único. Proibido estritamente de fazer
                  upload de arquivos que não seja de documento fiscal.
                </div>
              </Dragger>
            </Form.Item>
          )}
          <Form.Item className={styles.buttonArea}>
            <Button
              type="primary"
              className={styles.confirmar}
              htmlType="submit"
              loading={loading}
            >
              {isEditing ? 'Atualizar' : 'Cadastrar'}
            </Button>
            <Button
              onClick={() => navigate('/home')}
              type={'text'}
              style={{ marginTop: '10px' }}
            >
              Cancelar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
