import { Button, Checkbox, Form, Input, Modal, Spin } from 'antd';
import styles from './styles.module.css';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CodeVerification from 'services/code.verification';
import ReactInputMask from 'react-input-mask';
import { CheckCircleOutlined, SendOutlined } from '@ant-design/icons';
import { ModalContainer } from './styles';

interface ModalProps {
  isVisible: boolean;
  form: any;
  setIsVisible: (value: boolean) => void;
  cpfMask: (value: string) => void;
  isValideCPF: any;
  haveCpfOnInput: boolean;
}

export const RecoverPasswordModal = ({
  isVisible,
  setIsVisible,
  form,
  cpfMask,
  isValideCPF,
  haveCpfOnInput,
}: ModalProps) => {
  const [cpf, setCpf] = useState('');
  const [possuiCodigo, setPossuiCodigo] = useState(false);
  const [disableSendCode, setDisableSendCode] = useState(!haveCpfOnInput);

  useEffect(() => {
    if (haveCpfOnInput) {
      setDisableSendCode(false);
    } else {
      setDisableSendCode(true);
    }
  }, [haveCpfOnInput]);

  const [loading, setLoading] = useState(false);
  const label = 'Já recebi meu código de Recuperação';

  const finish = useCallback(async (values: any) => {
    try {
      const hasAllValues =
        values?.code &&
        values?.cpf &&
        values?.password &&
        values?.passwordConfirmation
          ? true
          : false;
      if (!hasAllValues) {
        return;
      }
      setLoading(true);

      if (values.password !== values.passwordConfirmation) {
        toast.error('As senhas não coincidem');
        setLoading(false);
        return;
      }

      const patt1 = /[0-9]/g;
      values.cpf = values.cpf.match(patt1).join('');

      await CodeVerification.createPassword(values);
      setLoading(false);
      toast.success('Senha Alterada');
      setIsVisible(false);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  function alteraLabel() {
    setPossuiCodigo(!possuiCodigo);
  }

  async function sendConde() {
    try {
      let validCpf = cpf;

      if (!validCpf) validCpf = form.getFieldValue('cpf');

      if (validCpf.length !== 14) {
        toast.error('CPF inválido');
        return;
      }
      const patt1 = /[0-9]/g;
      // @ts-ignore
      const cpfSend = (validCpf || '').match(patt1 || '').join('');
      setLoading(true);

      const { data } = await CodeVerification.sendCodeVerification(cpfSend);

      if (data?.messageForUser === 'Usuário não cadastrado') {
        throw new Error(data?.messageForUser);
      }

      toast.success(JSON.stringify(data.messageForUser));
      alteraLabel();
    } catch (e: any) {
      toast.error(
        'Ocorreu um erro ao enviar código, se persistir entrar em contato com o suporte: meucelular@sspds.ce.gov.br'
      );
    } finally {
      setLoading(false);
    }
  }

  const atualizaCPF = (e: any) => {
    cpfMask(e.target.value);
    const valor = e.target.value;
    setCpf(valor);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    setPossuiCodigo(false);
    form.setFieldsValue({ code: '', password: '', passwordConfirmation: '' });
  };

  const cpfValidation = (_: any, value: any) => {
    if (value && isValideCPF(value)) {
      setDisableSendCode(false);
      return Promise.resolve();
    }
    if (value) {
      setDisableSendCode(true);
      return Promise.reject('CPF inválido');
    }
    setDisableSendCode(true);
  };

  return (
    <ModalContainer
      destroyOnClose
      visible={isVisible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <Spin spinning={loading} className={styles.spin}>
        <Form onFinish={finish} form={form} name="basic">
          <div className={styles.container}>
            <div className={styles.cardVerification}>
              <div className={styles.contentTitle}>
                <h1> Recuperação de senha</h1>
              </div>
              <p>
                Passo 1 : Utilize seu CPF para enviar um código de recuperação
                para o email cadastro.
              </p>
              <p>
                Passo 2 : Utilize o código recebido no email para registrar uma
                nova senha.
              </p>

              <Form.Item
                label="CPF"
                // className={styles.inputFirstForm}
                // value={cpf}
                // defaultValue={cpf}
                name="cpf"
                rules={[
                  {
                    required: true,
                    validator: cpfValidation,
                  },
                ]}
              >
                <ReactInputMask
                  required
                  className={styles.input}
                  mask="999.999.999-99"
                  //   required
                  // value = {cpf}
                  name="cpf"
                  onChange={e => atualizaCPF(e)}
                  value={cpf}
                  //   defaultValue={cpf}
                  type="text"
                  placeholder="000.000.000-00"
                />
              </Form.Item>
              {!possuiCodigo && (
                <Button
                  disabled={disableSendCode}
                  className={
                    disableSendCode ? styles.btnSendDisabled : styles.btnSend
                  }
                  //   label="Gerar Código"
                  onClick={() => sendConde()}
                >
                  Gerar Código <SendOutlined />
                </Button>
              )}
              <Checkbox
                // disabled={disableSendCode}
                className={styles.labelCheck}
                checked={possuiCodigo}
                value={possuiCodigo}
                // disabled={possuiCodigo}
                onChange={() => alteraLabel()}
              >
                {label}
              </Checkbox>

              {possuiCodigo && (
                <div>
                  <Form.Item
                    label="Código"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ]}
                  >
                    <Input
                      className={styles.input}
                      required
                      type="text"
                      placeholder="Digite o código"
                      autoComplete="false"
                      /* onBlur={verificaVazio} */
                    />
                  </Form.Item>
                  <Form.Item
                    label="Nova Senha"
                    name="password"
                    rules={[
                      { required: true, message: 'Obrigatório' },
                      { min: 8 },
                    ]}
                  >
                    <Input.Password
                      className={styles.inputpassword}
                      required
                      type="text"
                      autoComplete="new-password"
                      placeholder="Senha"
                      value={cpf}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirmar Nova Senha"
                    name="passwordConfirmation"
                    rules={[
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ]}
                  >
                    <Input.Password
                      className={styles.inputpassword}
                      required
                      type="text"
                      placeholder="Confirmar senha"
                    />
                  </Form.Item>
                  <div className={styles.areaButton}>
                    <Button className={styles.btnConfirmar} htmlType="submit">
                      <CheckCircleOutlined /> Criar nova senha
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      </Spin>
    </ModalContainer>
  );
};
