import React from 'react';

import { Footer } from './styles';

export default function NewFooter() {
  return (
    <Footer>
      <div className="row">
        <div className="content-row sigla">
          <p>SSPDS.CE.GOV.BR</p>
          <h2>
            © 2017 - 2024 – GOVERNO DO ESTADO DO CEARÁ TODOS OS DIREITOS
            RESERVADOS
          </h2>
        </div>
        <div className="content-row endereco">
          <h2>SECRETARIA DA SEGURANÇA PÚBLICA E DEFESA SOCIAL</h2>

          <p>
            Av. Aguanambi, S/N - Aeroporto, Fortaleza - CE, 60415-390 EMAIL:
            meucelular@sspds.ce.gov.br
          </p>
        </div>
        <div className="content-row redesSociais">
          <h1>Desenvolvido por:</h1>
          <div className="icon">
            <p>
              <b>COTIC</b> - Coordenadoria de Tecnologia da Informação e
              Comunicação
            </p>
          </div>
        </div>
      </div>
    </Footer>
  );
}
