interface OrgaosExpeditoresProps {
    name: string;
    sigla: string;
}

export const listOrgaosExpeditores: OrgaosExpeditoresProps[] = [
    {name: 'Secretaria de Segurança Pública do Acre',	sigla: 'SSP/AC'},
    {name: 'Secretaria de Segurança Pública de Alagoas ',	sigla: 'SSP/AL'},
    {name: 'Secretaria de Segurança Pública do Amapá',	sigla: 'SSP/AP'},
    {name: 'Secretaria de Segurança Pública do Amazonas',	sigla: 'SSP/AM'},
    {name: 'Secretaria de Segurança Pública da Bahia',	sigla: 'SSP/BA'},
    {name: 'Secretaria de Segurança Pública do Ceará', sigla: 'SSPDS/CE'},
    {name: 'Secretaria de Segurança Pública do Distrito Federal',	sigla: 'SSP/DF'},
    {name: 'Secretaria de Segurança Pública do Espírito Santo', sigla:	'SESP/ES'},
    {name: 'Secretaria de Segurança Pública de Goiás',	sigla: 'SSP/GO'},
    {name: 'Secretaria de Segurança Pública do Maranhão',	sigla: 'SSP/MA'},
    {name: 'Secretaria de Segurança Pública do Mato Grosso',	sigla: 'SSP/MT'},
    {name: 'Secretaria de Segurança Pública do Mato Grosso do Sul',	sigla: 'SSP/MS'},
    {name: 'Secretaria de Segurança Pública de Minas Gerais',	sigla: 'SSP/MG'},
    {name: 'Secretaria de Segurança Pública do Pará',	sigla: 'SSP/PA'},
    {name: 'Secretaria de Segurança Pública da Paraíba',	sigla: 'SSP/PB'},
    {name: 'Secretaria de Segurança Pública do Paraná',	sigla: 'SSP/PR'},
    {name: 'Secretaria de Segurança Pública de Pernambuco',	sigla: 'SSP/PE'},
    {name: 'Secretaria de Segurança Pública do Piauí',	sigla: 'SSP/PI'},
    {name: 'Secretaria de Segurança Pública do Rio de Janeiro',	sigla: 'SSP/RJ'},
    {name: 'Secretaria de Segurança Pública do Rio Grande do Norte', sigla: 'SESED/RN'},
    {name: 'Secretaria de Segurança Pública do Rio Grande do Sul',	sigla: 'SSP/RS'},
    {name: 'Secretaria de Segurança Pública de Rondônia', sigla: 'SESDEC/RO'},
    {name: 'Secretaria de Segurança Pública de Roraima', sigla:'SESP/RR'},
    {name: 'Secretaria de Segurança Pública de Santa Catarina',	sigla: 'SSP/SC'},
    {name: 'Secretaria de Segurança Pública de São Paulo',	sigla: 'SSP/SP'},
    {name: 'Secretaria de Segurança Pública de Sergipe',	sigla: 'SSP/SE'},
    {name: 'Secretaria de Segurança Pública do Tocantins',	sigla: 'SSP/TO'},
]

// dados tirados de : https://neon.com.br/aprenda/economizar-dinheiro/orgao-emissor/
/** 
Estado	Órgão emissor do RG	Sigla
Acre (AC)	Secretaria de Segurança Pública do Acre	SSP/AC
Alagoas (AL)	Secretaria de Segurança Pública de Alagoas 	SSP/AL
Amapá (AP)	Secretaria de Segurança Pública do Amapá	SSP/AP
Amazonas (AM)	Secretaria de Segurança Pública do Amazonas	SSP/AM
Bahia (BA)	Secretaria de Segurança Pública da Bahia	SSP/BA
Ceará (CE)	Secretaria de Segurança Pública do Ceará	SSPDS/CE
Distrito Federal (DF)	Secretaria de Segurança Pública do Distrito Federal	SSP/DF
Espírito Santo (ES)	Secretaria de Segurança Pública do Espírito Santo	SESP/ES
Goiás (GO)	Secretaria de Segurança Pública de Goiás	SSP/GO
Maranhão (MA)	Secretaria de Segurança Pública do Maranhão	SSP/MA
Mato Grosso (MT)	Secretaria de Segurança Pública do Mato Grosso	SSP/MT
Mato Grosso do Sul (MS)	Secretaria de Segurança Pública do Mato Grosso do Sul	SSP/MS
Minas Gerais (MG)	Secretaria de Segurança Pública de Minas Gerais	SSP/MG
Pará (PA)	Secretaria de Segurança Pública do Pará	SSP/PA
Paraíba (PB)	Secretaria de Segurança Pública da Paraíba	SSP/PB
Paraná (PR)	Secretaria de Segurança Pública do Paraná	SSP/PR
Pernambuco (PE)	Secretaria de Segurança Pública de Pernambuco	SSP/PE
Piauí (PI)	Secretaria de Segurança Pública do Piauí	SSP/PI
Rio de Janeiro (RJ)	Secretaria de Segurança Pública do Rio de Janeiro	SSP/RJ
Rio Grande do Norte (RN)	Secretaria de Segurança Pública do Rio Grande do Norte	SESED/RN
Rio Grande do Sul (RS)	Secretaria de Segurança Pública do Rio Grande do Sul	SSP/RS
Rondônia (RO)	Secretaria de Segurança Pública de Rondônia	SESDEC/RO
Roraima (RR)	Secretaria de Segurança Pública de Roraima	SESP/RR
Santa Catarina (SC)	Secretaria de Segurança Pública de Santa Catarina	SSP/SC
São Paulo (SP)	Secretaria de Segurança Pública de São Paulo	SSP/SP
Sergipe (SE)	Secretaria de Segurança Pública de Sergipe	SSP/SE
Tocantins (TO)	Secretaria de Segurança Pública do Tocantins	SSP/TO
*/