import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
  // eslint-disable-next-line no-unused-vars
  Form,
  Button,
  Input,
  Spin,
} from 'antd';
import { CheckCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import styles from './styles.module.css';

import devPrint from '../../utils/devPrint';
import CodeVerification from '../../services/code.verification';

const red = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;

const strongPasswordRegex = new RegExp(red);

export default function PageValidation() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const cpf = location.pathname.includes('/page-code')
    ? location.pathname.split('/')[2]
    : '';

  const finish = useCallback(async values => {
    let error = false;
    setLoading(true);
    // devPrint(JSON.stringify(values));
    // devPrint(cpf);
    try {
      // devPrint(values);
      if (values.password !== values.passwordConfirmation) {
        toast.error('As senhas não coincidem');
        setLoading(false);
        return;
      }

      const newUser = {
        ...values,
        cpf: values.cpf.replaceAll('.', '').replaceAll('-', ''),
      };

      devPrint(newUser);

      // eslint-disable-next-line no-unused-vars
      const { data } = await CodeVerification.saveCodeVerification(newUser);
      navigate(`/page-code/${values.cpf}`);
    } catch (e) {
      error = true;
      if (e.response) {
        devPrint(e);
        if (e.response.status === 401) {
          toast.error('Dados inválidos');
        } else if (e.response.status === 500) {
          toast.error('Falha de conexão com o servidor');
        } else {
          toast.error(e.response.status);
        }
      } else if (e.request) {
        devPrint(`Erro Request:${e.request}`);
        toast.error('Falha de conexão com o servidor');
        // toast.error(e.request.data.messageForUser);
      }
    } finally {
      setLoading(false);
    }

    if (!error) {
      toast.info('Conta criada');
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      cpf,
    });
  }, [form, cpf]);

  return (
    <Spin spinning={loading} className={styles.spin}>
      <Form layout="vertical" onFinish={finish} form={form} name="basic">
        <div className={styles.container}>
          <div className={styles.cardVerification}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <LeftOutlined
                id={styles.backButton}
                onClick={() => navigate('/login')}
              />
              <h1>Validação da conta</h1>
            </div>
            <p>
              Copie e cole o código de segurança enviado para o e-mail
              informado.
            </p>
            <Form.Item
              name="cpf"
              label="CPF"
              mask="999.999.999-99"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <Input
                className={styles.input}
                required
                disabled="disabled"
                type="text"
                placeholder="CPF"

                /* onBlur={verificaVazio} */
              />
            </Form.Item>
            <Form.Item
              label="Código"
              name="code"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <Input
                className={styles.input}
                required
                type="text"
                placeholder="Digite o código"
                /* onBlur={verificaVazio} */
              />
            </Form.Item>
            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
                { min: 8 },
                {
                  validator: (_, value) =>
                    value && value.match(strongPasswordRegex)
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'Senha informada deve ser forte (maiusculo, minusculo, numero e caracter especial)'
                          )
                        ),
                },
              ]}
            >
              <Input.Password
                className={styles.input}
                required
                type="password"
                placeholder="Digite a senha"
              />
            </Form.Item>
            <Form.Item
              label="Confirmar senha"
              name="passwordConfirmation"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <Input.Password
                className={styles.input}
                required
                placeholder="Confirme a senha"
                type="text"
              />
            </Form.Item>
            <div className={styles.areaButton}>
              <Button className={styles.btnConfirmar} htmlType="submit">
                <CheckCircleOutlined /> Validar conta
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Spin>
  );
}
