import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  html {
    --antd-wave-shadow-color: #189122 !important;
  }
  body {
    -webkit-font-smoothing: antialiased;
    background: #fff;
  }
  body, input, button {
    font: 16px sans-serif !important;
  }
 h1,h2,h3,h4,h5,h6, strong{
   font-weight: 500;
 }
  button {
    cursor: pointer;
    border: none !important;
  }

  .slotRodape{

  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    /* position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9; */
    border-radius: 4px;
    /* transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
  }

  .loginScreen {
    min-height: 92vh !important;
    padding: 0px;
    align-items: center;
    @media screen and (max-width: 600px) {
      min-height: 120vh !important;
    }
  }

  .ant-btn-primary {
    background: rgba(0, 128, 0, .8) !important;
    border: 1px solid green !important;
    color: white !important;
  }

  .ant-btn-primary:hover {
    background: rgba(0, 128, 0, 1) !important;
    color: white !important;
  }

  .ant-btn-text {
    background: rgba(255, 69, 0, 0.8) !important;
    border: 1px solid rgba(255, 69, 0, 1) !important;
    color: white !important;
  }

  .ant-btn-text:hover {
    background: rgba(255, 69, 0, 1) !important;
    color: white !important;
  }

  .ant-upload, .ant-upload-drag:hover {
    border-color: green!important;
  }

  .ant-input {
    border-radius: 10px !important;
  }

  .ant-select-selector {
    border-radius: 10px !important;
  }

  .ant-picker {
    border-radius: 10px !important;
  }

  .ant-input:hover,
  .ant-select-selector:hover,
  .ant-picker:hover {
    border-color: #2fac6d !important;
  }

  .ant-breadcrumb li:last-child {
    color: green !important;
  }

  .ant-breadcrumb {
    font-size: 18px !important;
  }

  .ant-spin-sm .ant-spin-dot i {
    background-color: green !important;
  }

  .ant-spin-dot-item {
    background-color: green !important;
  }

  .ant-btn-dashed:hover {
    color: green;
    border 1px dashed green !important;
  }

  .ant-breadcrumb li:last-child a {
    color: green !important;
  }
`;
