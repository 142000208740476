/* eslint-disable no-unused-vars */
// import api from './api';

import { toast } from 'react-toastify';
import devPrint from '../utils/devPrint';
import api from './api';

function cellphoneService() {
  const errorHandler = err => {
    toast.error(err.message, {
      toastId:`CustomId ${err?.status}`
    });
    // devPrint(err);
    // if (err.response) {
    //   toast.error(JSON.stringify(err.response.data.message));
    //   console.log("RENDERIZANDO ERRO = ", err.error[0]);
    //   devPrint(`Erro Response:${err.response.status}`);
    //   if (err.response.status === 404) {
    //     throw new Error(404);
    //   } else if (err.response.status === 401) {
    //     throw new Error(401);
    //   } else if (err.response.status === 400) {
    //     throw new Error(400);
    //   } else {
    //     devPrint(`Erro Response:${err.response.status}`);
    //     // toast.error(JSON.stringify(err.response.status));
    //     // toast.error(err.response.data.message, {
    //     //   toastId: err.response.data.message,
    //     // });
    //     throw err;
    //   }
    // } else {
    //   devPrint(`Erro Request:${err.request}`);
    //   // toast.error(JSON.stringify(err.request));
    //   toast.error(err.request.data.message, {
    //     toastId: err.response.data.message,
    //   });
    //   throw err;
    // }
  };

  // REGISTER PHONE and HOME
  // * CRUD MODELO
  //  api.get(`modelo/fabricante?nome=${fabricanteName}`)
  const getModelFromManufacturers = async manufacturerName => {
    try {
      const res = await api.get(`modelo/fabricante?nome=${manufacturerName}`);
      return res.data;
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  //  api.get('modelo/fabricante')

  // * CRUD FABRICANTE
  //  api.get('fabricantes/ativos')
  const getAllManufacturers = async () => {
    try {
      const res = await api.get('fabricantes/ativos');
      return res.data;
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  // * CRUD CELULAR
  // api.post('celular', body)
  const saveCellphone = async body => {
    try {
      const res = await api.post('celular', body);
      devPrint(res);
      return res;
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  // api.get(`celular/unique?id=${id}`)
  const getCellhoneData = async id => {
    try {
      const res = await api.get(`celular/unique?id=${id}`);
      devPrint(res);
      return res.data;
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };
  // api.get(`/celular/documento?id=${id}`, {responseType: 'arraybuffer',})
  const getDocumentFromId = async id => {
    try {
      const res = await api.get(`/celular/documento?id=${id}`, {
        responseType: 'arraybuffer',
      });
      const { data, headers } = res;
      return { data, headers };
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  // api.get(`celular/ocorrencia?id=${id}`)
  const getOcorrenciaFromId = async id => {
    try {
      const res = await api.get(`celular/ocorrencia?id=${id}`);
      devPrint(res);
      return res.data;
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  // api.get('celular/all')
  const getAllFromUser = async () => await api.get('celular/all');

  // api.get('celular/situacao')
  const getStatus = async () => {
    try {
      const res = await api.get('celular/situacao');
      devPrint(res);
      return { data: res.data, msg: res.message };
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  // api.get('/celular/ocorrencia/tipo/procedimento')
  const getProcedimentoType = async () => {
    try {
      const res = await api.get('/celular/ocorrencia/tipo/procedimento');
      devPrint(res);
      return { data: res.data, msg: res.message };
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  // api.put(`celular/status/alerta?id=${id}`, values)
  const addAlert = async (id, values) => {
    try {
      const res = await api.put(`celular/status/alerta?id=${id}`, values);
      devPrint(res);
      return res?.data;
    } catch (err) {
      errorHandler(err);
    }
    return {};
  };
  // api.put(`celular?id=${id}`, body)
  const updateCellphone = async (id, body) => {
    try {
      const res = await api.put(`celular?id=${id}`, body);
      devPrint(res);
      return res;
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };
  // api.put(`celular/status/recuperado?id=${id}`, values)
  const updateCellphoneToRecovered = async (id, values) => {
    try {
      const res = await api.put(`celular/status/recuperado?id=${id}`, values);
      devPrint(res);
      return { data: res.data, msg: res.message };
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  // api.put(`celular/status/pre/alerta?id=${id}`, values)
  const updateCellphonePreAlert = async (id, values) => {
    try {
      const res = await api.put(`celular/status/pre/alerta?id=${id}`, values);
      devPrint(res);
      return { data: res.data, msg: res.message };
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  // api.put(`celular/status/remove/alerta?id=${id}`, values)
  const rollbackCellphoneStatus = async (id, values) => {
    try {
      const res = await api.put(
        `celular/status/rollback?id=${id}`,
        values
      );
      return { data: res.data, msg: res.message };
    } catch (err) {
      errorHandler(err);
    }
    return {};
  };

  // const rollbackCellphoneStatus = async (id, values) => {
  //   try {
  //     const res = await api.put(
  //       `celular/status/rollback?id=${id}`,
  //       values
  //     );
  //     return { data: res.data, res: res.message };
  //   } catch (err) {
  //     errorHandler(err);
  //   }
  //   return {};
  // };

  // api.put(`celular/status/remove/pre/alerta?id=${id}`, values)
  const removeCellphonePreAlert = async (id, values) => {
    try {
      const res = await api.put(
        `celular/status/rollback?id=${id}`,
        values
      );
      devPrint(res);
      return { data: res.data, res: res.message };
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };
  // api.delete(`celular?id=${id}`)
  const deleteCellphone = async id => {
    try {
      const res = await api.delete(`celular?id=${id}`);
      devPrint(res);
      return { data: res.data, msg: res.message };
    } catch (err) {
      devPrint(err);
      errorHandler(err);
    }
    return {};
  };

  const cellphoneHistory = async id => {
    try {
      const res = await api.get(`/celular/ocorrencia/historico?id=${id}`);
      devPrint(res);
      return res.data;
    } catch (err) {
      errorHandler(err);
    }
    return {};
  };

  return {
    getModelFromManufacturers,
    getAllManufacturers,
    saveCellphone,
    getCellhoneData,
    getDocumentFromId,
    getOcorrenciaFromId,
    getAllFromUser,
    getStatus,
    getProcedimentoType,
    addAlert,
    updateCellphone,
    updateCellphoneToRecovered,
    updateCellphonePreAlert,
    // removeCellphoneAlert,
    rollbackCellphoneStatus,
    removeCellphonePreAlert,
    deleteCellphone,
    cellphoneHistory
  };
}

// api;
export default cellphoneService;
