import { Breadcrumb } from "antd";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BreadCrumbStyled } from "./style";

export default function BreadcrumbsComponent() {
    const location = useLocation();
    const pagesNavigation = useRef<string[]>([]);
    let prev: string | null = null;
    let currentPage = '';
    let currentPath = '';

    // filtra as urls para retirar as duplicadas e as com /home
    pagesNavigation.current = Array(location.pathname).filter(crumb => {
        if((prev && prev === crumb) || crumb === '/home') {
            return false;
        }
        prev = crumb;
        return true;
    });

    let crumbs = [{
        page: '',
        path: ''
    }]
    pagesNavigation.current.map(page => {
        page = page.replace('u', 'ú'); // coloca acentuação na página de dúvidas frequentes
        
        // caso a url inclua dois separador, coloca o path do anterior para /home
        if(page.includes('/')) {
            page.split('/').map(p => {
                crumbs.push({
                    page: p,
                    path: '/home'
                });
                currentPage = p;
            });
        } else {
            crumbs.push({
                page: page.replace('/', ''),
                path: `/${page}`
            });
        }
        currentPath += page;
    });

    crumbs = crumbs.filter(crumb => crumb.page !== '') // retira as pagina com string vazia

    return(
        <BreadCrumbStyled>
                <Breadcrumb.Item>
                    <Link onClick={() => pagesNavigation.current = []} to={'/home'}>
                        {
                            pagesNavigation.current.length ?
                            <span style={{ fontSize: '1.3rem' }} >Início</span> :
                            <span style={{fontSize:'1.3rem', color: 'green'}} >Início  /</span>
                        }
                         
                    </Link>
                </Breadcrumb.Item>
                {crumbs.map((crumb, index) => {                    
                    if(crumb.page === currentPage) {
                        return (
                            <Breadcrumb.Item key={index}>
                                <span style={{ fontSize: '1.3rem' }}>{crumb.page}</span>
                            </Breadcrumb.Item>
                        )
                    }
                    return (
                        <Breadcrumb.Item key={index}>
                                <Link 
                                    onClick={() => {
                                        pagesNavigation.current = pagesNavigation.current.filter(p => p === crumb.path);
                                    }}
                                    to={crumb.path}
                                >
                                    <span style={{ fontSize: '1.3rem' }}>{crumb.page}</span>
                                </Link>
                        </Breadcrumb.Item>
                    )
                })}  
        </BreadCrumbStyled>
    );
}