import { apiAuth } from './auth';

const CodeVerification = {
  saveCodeVerification: ({ cpf, code, password }) =>
    apiAuth.post('createPassword', {
      cpf,
      code,
      password,
    }),

  sendCodeVerification: cpf => apiAuth.post(`sendcode?cpf=${cpf}`),

  createPassword: values => apiAuth.post('createPassword', values),
};
// export const SendCode = {

// };
export default CodeVerification;
